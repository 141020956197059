import axios from "../utils/axios";

export function cartItemCreate(params) {
    return axios.post('/member/cart-item/create', params);
}
export function orderPreview(params) {
    return axios.get(`/order/order/preview?type=${params.type}&data=${params.data}` );
}
export function getAddress(params) {
    return axios.get(`/member/address?page=${params}`);
}
export function getAddress1(id) {
    return axios.get(`/member/address/${id}`);
}
export function handleAddressCreate(params) {
    return axios.post('/member/address',params);
}
export function handleAddressCreate1(params, id) {
    return axios.put(`/member/address/${id}`,params);
}
export function getCoupons(params) {
    return axios.post('/marketing/coupon-type',params);
}
export function cartItemClear(params) {
    return axios.post('/member/cart-item/clear',params);
}
