<template>
  <div>
    <s-header :name="'商品详情'"></s-header>
    <div class="swiperWrap">
      <swiper style="height: 100%" :list="swiperList"></swiper>
    </div>
    <div class="content">
      <div class="info">
        <p>
          <span class="price"><span style="font-size: 18px">¥</span>{{ details.price }}</span>
          <span v-if="details.market_price" style="text-decoration: line-through">原价 ¥{{ details.market_price }}</span>
        </p>
        <p class="title">{{ details.name || '暂无商品详情' }}</p>
        <p>【发货地址】 {{ details.address_name }}</p>
      </div>
      <div class="explain">
        <p style="margin-bottom: 10px">
          <span>优惠卷：</span>
          <span style="margin-left: 10px;color: red" @click="coupon=true"
                v-if="details.canReceiveCoupon && details.canReceiveCoupon.length >0">领取优惠券</span>
          <span v-else style="color: red">暂无优惠卷</span>
        </p>
        <p style="margin-bottom: 10px" v-if="details.tags && details.tags.length >= 1">
          <span>服务：</span>
          <span class="serve" style="margin-left: 10px" v-for="val in details.tags" :key="val.id">{{ val.title }}</span>
        </p>
        <p style="margin-bottom: 10px" v-else>暂无服务</p>
        <div v-if="details.attribute_value && details.attribute_value.length >= 1">
          <p style="margin-bottom: 10px" v-for="val in details.attribute_value" :key="val.id">
            <span>{{ val.title }}：</span>
            <span class="serve">{{ val.value }}</span>
          </p>
        </div>
        <p v-else>暂无商品基本信息</p>
      </div>
      <div class="img">
        <p class="title">图文详情</p>
        <div v-html="details.intro"></div>
      </div>
    </div>
    <div class="pay">
      <van-action-bar>
        <van-action-bar-icon :icon="isFollow ? 'star-o' : 'star'" text="收藏" @click="onFollow" />
        <van-action-bar-icon icon="cart-o" text="购物车" @click="seeShoppingCart" />
        <van-action-bar-button type="warning" text="加入购物车" @click="onClick" v-if="details.is_pt !== 1" />
        <van-action-bar-button type="danger" text="立即购买" @click="onBuy"  v-if="details.is_pt !== 1" />
        <van-action-bar-button type="danger" text="立即拼团" @click="onBuy"  v-if="details.is_pt == 1" />

      </van-action-bar>
    </div>
    <van-action-sheet v-model:show="show">
      <div class="buy">
        <div class="top">
          <img style="width: 100px;margin-right: 10px" :src="details.picture"/>
          <div>
            <p style="margin-bottom: 10px">{{ details.name }}</p>
            <p style="color: red">
              <span><span style="font-size: 14px">¥</span>{{ chooseInfo.price }}</span>
              <span class="marketPrice">¥{{ chooseInfo.market_price }}</span>
            </p>
            <p>库存：{{ chooseInfo.stock }}</p>
            <p>已选：{{ chooseInfo.name }}</p>
          </div>
        </div>
        <ul class="RadioWrap">
          <li v-for="(val, index) in details.specs" :key="index" style="margin-bottom: 10px">
            <p>{{ val.desc }}</p>
            <div class="RadioStyle">
              <div class="radioList">
                <div v-for="item in val.value" :key="item.id" @click="onChoose(index, item.title,item.id)">
                  <span :class="radioParam[index].title === item.title ? 'RadioStyleActive' : ''">{{
                      item.title
                    }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="num">
          <span>选择数量</span>
          <van-stepper v-model="value" disable-input/>
        </div>
        <van-button class="btn" color="linear-gradient(to right, #ff6034, #ee0a24)" @click="sure"
                    v-if="chooseInfo.stock>0">
          确定
        </van-button>
        <van-button class="btn" color="linear-gradient(to right, #ff6034, #ee0a24)" disabled
                    v-else>
          暂无库存
        </van-button>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model:show="coupon" style="background: #f3f3f3">
      <div class="coupon-item" v-for="(item, index) in details.canReceiveCoupon" :key="index"
           style="margin-bottom: 10px" @click="claimCoupons(item.id)">
        <div class="con">
          <div class="left">
            <span class="title">{{ item.title }}</span>
            <span class="time">有效期{{ item.start_time }}至{{ item.end_time }}</span>
          </div>
          <div class="right">
            <span class="price" v-if="item.money">{{ item.money }}</span>
            <span class="price-discount" v-else>{{ `${item.discount}折` }}</span>
            <span>满{{ item.at_least }}可用</span>
          </div>
          <div class="circle l"></div>
          <div class="circle r"></div>
        </div>
        <div class="tips" >
          <span v-if="item.range_type && item.max_fetch">
            {{ item.range_type === 0 ? '部分产品使用' : '全场产品使用' }} 领取上限{{item.max_fetch}}
          </span>
          <span>
            {{ item.term_of_validity_type === 0 ? '固定时间' : '领取之日起' }}生效
          </span>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {reactive, onMounted, toRefs, ref, computed} from 'vue'
import {getProduct} from '@/service/home'
import sHeader from '@/components/SimpleHeader'
import swiper from '@/components/Swiper'
import {useRouter} from 'vue-router'
import {getGoodDetails, goodsFollow} from '@/service/good'
import {showSuccessToast} from "vant";
import {cartItemCreate, getCoupons} from "@/service/product";

export default {
  components: {
    sHeader,
    swiper
  },
  setup() {
    const show = ref(false);
    const isFollow = ref(false)
    const coupon = ref(false);
    const router = useRouter()
    const state = reactive({
      // 轮播图列表
      swiperList: [],
      // 商品详情
      details: {},
      // 选择数量
      value: 1,
      // 单选值
      radioParam: [],
      isBuy: false,
    })
    const chooseInfo = computed(() => {
      const name = state.radioParam.map(item => `${item.title},`).join("")
      console.log(name)
      const result = state.details && state.details.sku && state.details.sku.find(item => item.name.indexOf(name) !== -1)
      const priceNow = result || {
        price: '',
        market_price: '',
        stock: ''
      }
      // console.log(result)
      return priceNow
    })
    onMounted(async () => {
      const {data} = await getGoodDetails({id: router.currentRoute.value.query.id})
      if(data.follow_status == 1){
        isFollow.value = false
      } else {
        isFollow.value = true
      }
      state.swiperList = data.covers
      state.details = data
      state.details.specs.map((item, index) => {
        state.radioParam[index] = {
          id: item.value[0].id,
          title: item.value[0].title,
        }
      })
    })

    function seeShoppingCart() {
      router.push({path: "/cart"})
    }

    async function onFollow() {
      const data = await goodsFollow({product_id: router.currentRoute.value.query.id})
      isFollow.value = !isFollow.value
      showSuccessToast(data.message)
    }

    function onClick() {
      show.value = true
      state.isBuy = false
    }

    function onBuy() {
      show.value = true
      state.isBuy = true
    }

    function onChoose(index, title, id) {
      state.radioParam[index] = {
        id,
        title,
      }
      // console.log(title);
      // console.log(state.radioParam);
    }

    // 获取商品列表
    const getList = async () => {
      const {data} = await getProduct()
      state.shopList = data
    }

    // 领取优惠券
    async function claimCoupons(id) {
      const {code} = await getCoupons({id})
      if (code == 200) {
        showSuccessToast('领取成功！')
        coupon.value = false
      }
    }

    const sure = async () => {
      for (let i = 0; i < state.details.specs.length; i++) {
        if (!state.radioParam[i]) {
          const item = state.details.specs[i]
          console.log(item.desc)
          return
        }
      }
      let sku_id;
      let product_id;
      let skuStr;
      if (state.details.is_attribute == "1") {
        if (state.details.sku.length === 1) {
          sku_id = state.details.sku[0].id
        } else {
          if (state.details.specs.length === 1) {
            skuStr = `${state.radioParam[0].id}`
          } else if (state.details.specs.length === 2) {
            skuStr = `${state.radioParam[0].id}-${state.radioParam[1].id}`
          } else if (state.details.specs.length === 3) {
            skuStr = `${this.specSelected[0].id}-${this.specSelected[1].id}-${this.specSelected[2].id}`
          }
          state.details && state.details.sku.forEach(item => {
            if (item.data.slice(0, item.data.length - 1) == skuStr) {
              sku_id = item.id;
              return;
            }
          })
        }
      } else {
        product_id = state.details.id
      }
      const data = JSON.stringify({
        type: "buy_now",
        data: {
          num: state.value,
          sku_id
        }
      })
      if (state.isBuy) {
        router.push({path: '/good/order', query: {data}})
      } else {
        const opinion = await cartItemCreate({
          sku_id: sku_id,
          product_id,
          num: state.value
        })
        if (opinion.code == 200) {
          show.value = false
          showSuccessToast('加购成功！')
        }
        // if (opinion.data.length === 0) {
        //   state.finished = true;
        // }

      }
      // todo
    }
    return {
      show,
      coupon,
      isFollow,
      onClick,
      onChoose,
      getList,
      chooseInfo,
      onBuy,
      sure,
      onFollow,
      claimCoupons,
      seeShoppingCart,
      ...toRefs(state)
    }
  },
}
</script>

<style scoped lang="less">


/* 优惠券列表 */
.coupon-item {
  display: flex;
  flex-direction: column;
  margin: 10px 14px;
  padding: 10px;

  .con {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;
    background: #fff;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 0;
      border-bottom: 1px dashed #f3f3f3;
      transform: scaleY(50%);
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    height: 100px;
  }

  .title {
    font-size: 16px;
    color: #303133;
    margin-bottom: 10px;
  }

  .time {
    font-size: 16px;
    color: #909399;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #606266;
    height: 100px;
  }

  .price {
    font-size: 16px;
    color: #fa436a;

    &:before {
      content: '￥';
      font-size: 16px;
    }
  }

  .price-discount {
    font-size: 16px;
    color: #fa436a;
  }

  .tips {
    font-size: 16px;
    color: #909399;
    line-height: 60px;
    padding-left: 30px;
  }

  .circle {
    position: absolute;
    left: -6px;
    bottom: -10px;
    z-index: 10;
    width: 20px;
    height: 20px;
    background: #f3f3f3;
    border-radius: 100px;

    &.r {
      left: auto;
      right: -6px;
    }
  }

}

.swiperWrap {
  width: 100%;
  height: 400px;
}

.content {
  margin-bottom: 60px;

  p {
    margin: 0;
  }

  background: #F9F9F9;
  padding: 10px;

  .info {
    padding: 10px;
    background: #fff;
    border-radius: 12px;
    color: #ccc;
    font-size: 18px;

    .price {
      margin-right: 10px;
      font-size: 30px;
      color: red;
      font-weight: bold;
    }

    .title {
      margin: 10px 0;
      color: #333;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .explain {
    margin: 10px 0;
    background: #fff;
    padding: 10px;
    color: #ccc;
    font-size: 16px;

    .serve {
      color: #333;
      font-size: 18px;
    }
  }

  .img {
    background: #fff;

    .title {
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
    }

    ::v-deep .wscnph {
      max-width: 100% !important;
    }
  }
}

.pay {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.buy {
  margin: 20px 10px;
  font-size: 16px;
  color: #333;

  p {
    margin: 0;
  }

  .top {
    padding: 16px 10px;
    border-bottom: 1px solid #eee;
    display: flex;

    .marketPrice {
      color: #333;
      margin-left: 10px;
      text-decoration: line-through;
    }
  }

  .num {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .RadioWrap {
    margin: 16px;

    .radioList {
      display: flex;
      margin: 10px 0;
    }

    .RadioStyle input {
      line-height: 30px;
      height: 30px;
      border-radius: 2px;
      display: none;
    }

    .RadioStyle span {
      border: 1px solid rgb(175, 175, 175);
      line-height: 30px;
      height: 30px;
      font-size: 14px;
      padding: 0px 10px;
      display: inline-block;
      border-radius: 2px;
      margin: 5px 8px 5px 0;
      text-align: center;
    }

    span.RadioStyleActive {
      border: 1px solid rgb(254, 164, 25);
      color: rgb(254, 164, 25)
    }
  }

  .btn {
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
  }
}
</style>
